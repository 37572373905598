<template>
    <Popover v-model:open="isPopoverOpen">
        <PopoverTrigger as-child>
            <Button variant="primary" :size="props.size">
                <Icon name="plus" />
                <span>{{ $t("BOMTool.AddToBOM") }}</span>
            </Button>
        </PopoverTrigger>
        <PopoverContent class="p-0">
            <ButtonGroup class="p-2">
                <input
                    v-model="newBOMName"
                    type="text"
                    class="text-sm flex-grow"
                    :placeholder="createInputPlaceHolder"
                    @input="isNameChanged = true"
                    @focus="createInputFocus = true"
                    @blur="createInputFocus = false" />
                <Button :disabled="!isValid && !isBusy" @click="addPartToNewBOM()">
                    <Icon name="plus" />
                </Button>
            </ButtonGroup>
            <div class="m-1 text-xs text-danger">{{ validationError }}</div>
            <div v-if="isBusy" class="p-1 text-sm flex gap-2">
                <span>{{ $t("BOMTool.Loading") }}</span>
                <Spinner size="sm" />
            </div>
            <ul v-else class="divide-y max-h-[33vh] overflow-y-auto text-sm">
                <li v-for="bom in sortedBOMs" :key="bom.HashId" class="block hover:bg-gray-100 truncate">
                    <div v-if="bom.PartExists" class="flex justify-between p-1.5 gap-1">
                        <span>
                            {{ bom.Name }} <i class="text-muted">{{ $t("BOMTool.Added") }}</i>
                        </span>
                        <a :href="'/bom/' + bom.HashId">
                            {{ $t("Global.View") }}
                        </a>
                    </div>
                    <div
                        v-else
                        class="flex justify-between p-1.5 cursor-pointer focus:bg-gray-100 focus:outline-none"
                        tabindex="0"
                        @click="addPartToBOM(bom)">
                        <span>{{ bom.Name }}</span>
                    </div>
                </li>
            </ul>
        </PopoverContent>
    </Popover>
</template>
<script setup lang="ts">
import type { ButtonVariants } from "../ui";
import { safeParse } from "valibot";

const { gtag } = useGtag();
const { t } = useI18n();
const { toast } = useToast();

const { fetchBOMs, createBOM, addBOMPart, saveBOM, BomNameSchema } = useBOM();

const props = defineProps<{ part: BOMSearchPart; size: ButtonVariants["size"] }>();

const isPopoverOpen = ref(false);
const allBOMs = ref<BOMSearch[] | null>(null);
const isBusy = ref(false);
const newBOMName = ref("");
const createInputFocus = ref(false);
const isNameChanged = ref(false);

const createInputPlaceHolder = computed(() =>
    t(createInputFocus.value ? "BOMTool.BOMTitlePlaceHolder" : "BOMTool.BOMUploadTitle")
);

const parseResult = computed(() => safeParse(BomNameSchema, newBOMName.value));

const isValid = computed(() => parseResult.value.success);

const validationError = computed(() => {
    if (isValid.value || !isNameChanged.value || !Array.isArray(parseResult.value?.issues)) return "";
    return parseResult.value.issues[0].message;
});

const sortedBOMs = computed<BOMSearch[]>(() =>
    Array.isArray(allBOMs.value) ? allBOMs.value.toSorted((bomA, bomB) => bomA.Name.localeCompare(bomB.Name)) : []
);

async function onToggleOpen(isOpen: boolean) {
    if (!isOpen) return;
    isNameChanged.value = false;
    newBOMName.value = "";

    try {
        if (allBOMs.value === null) {
            isBusy.value = true;
            allBOMs.value = await fetchBOMs({
                manufacturerId: props.part.ManufacturerId,
                partNumber: props.part.PartNumberScrubbedNonMeaningful,
                requireEdit: true,
            });
        }
        gtag("event", "bom_tool_open_add_to_bom", { user_type: "Authenticated" });
    } catch (e) {
        handleError(e as Error);
    } finally {
        isBusy.value = false;
    }
}

async function addPartToNewBOM() {
    if (!isValid.value) return;
    try {
        const newBOM = await createBOM(newBOMName.value, props.part);
        updateBOMs(newBOM);
        toast({ title: t("BOMTool.Success"), description: t("BOMTool.SuccessfullyAddedToBOM"), variant: "success" });
        return newBOM;
    } catch (e) {
        handleError(e as Error);
    } finally {
        isPopoverOpen.value = false;
    }
}

async function addPartToBOM(bomSearch: BOMSearch) {
    try {
        isBusy.value = true;
        const updatedBOM = await addBOMPart(bomSearch, props.part);
        if (updatedBOM) {
            await saveBOM(updatedBOM);
            updateBOMs(updatedBOM);
            toast({
                title: t("BOMTool.Success"),
                description: t("BOMTool.SuccessfullyAddedToBOM"),
                variant: "success",
            });
            gtag("event", "bom_tool_add_to_bom", {
                manufacturer_name: props.part.ManufacturerName,
                part_number: props.part.PartNumberScrubbedNonMeaningful,
            });
        }
    } catch (e) {
        handleError(e as Error);
    } finally {
        isBusy.value = false;
        isPopoverOpen.value = false;
    }
}

function updateBOMs(bomSearch: BOMSearch) {
    if (!Array.isArray(allBOMs.value)) {
        allBOMs.value = [bomSearch];
    } else {
        allBOMs.value = [...allBOMs.value.filter((bom) => bom.HashId != bomSearch.HashId), bomSearch];
    }
}

function handleError(error: Error) {
    toast({ title: error.message || t("Global.GenericError"), variant: "warning" });
}

watch(isPopoverOpen, onToggleOpen);
</script>
