<script lang="ts"></script>
<template>
    <div class="inline-block" data-testid="part-add-to-bom">
        <BomPartAddAuth v-if="isAuthenticated" :part="bomSearchPart" :size="props.size" />
        <BomPartAddAnon v-else :part="bomSearchPart" :size="props.size" />
    </div>
</template>
<script setup lang="ts">
import type { ButtonVariants } from "../ui";
const isAuthenticated = useStateIsAuthenticated();

const props = withDefaults(
    defineProps<{
        // This is a catch-all for different part objects, this component converts them to BOMSearchPart
        part: {
            PartId: number;
            PartNumber: string;
            Key: string;
            PartNumberScrubbedNonMeaningful?: string;
            ScrubbedPartNumberNonMeaningful?: string;
            ManufacturerId?: number;
            ManufacturerName?: string;
            Manufacturer?: { Id: number; Name: string };
        };
        size?: ButtonVariants["size"];
    }>(),
    {
        size: "md",
    }
);

const manufacturerId = computed(() => props.part.ManufacturerId ?? props.part.Manufacturer?.Id);

const manufacturerName = computed(() => props.part.ManufacturerName ?? props.part.Manufacturer?.Name);

const partNumberScrubbed = computed(
    () => props.part.PartNumberScrubbedNonMeaningful ?? props.part.ScrubbedPartNumberNonMeaningful
);

const bomSearchPart = computed<BOMSearchPart>(() => {
    return {
        Id: 0,
        PartId: props.part.PartId,
        PartNumber: props.part.PartNumber ?? "",
        PartNumberScrubbedNonMeaningful: partNumberScrubbed.value ?? "",
        ManufacturerId: manufacturerId.value ?? 0,
        ManufacturerName: manufacturerName.value ?? "",
        Key: props.part.Key,
        Quantity: 1,
    };
});
</script>
